<template>
  <header class="header van-hairline--bottom">
    <i v-if="!isback" class="icon" @click="goBack">
        <van-icon name="arrow-left" />
    </i>
    <div class="header-name">{{ name }}</div>
  </header>
  <div class="block" />
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    back: {
      type: String,
      default: ''
    },
    noback: {
      type: Boolean,
      default: false
    }
  },
  emits: ['callback'],
  setup(props, ctx) {
    const isback = ref(props.noback)
    const router = useRouter()
    const goBack = () => {
      if (!props.back) {
        router.go(-1)
      } else {
        router.push({ path: props.back })
      }
      ctx.emit('callback')
    }
    return {
      goBack,
      isback
    }
  }
}
</script>

<style lang="less" scoped>
  @import '../style/mixin';

  .header {
    //position: fixed;
    //top: 0;
    //left: 0;
    z-index: 10000;
    .wh(100%, 50px);
    line-height: 50px;
    padding: 0 10px;
    .boxSizing();
    justify-content: center;
    color: #252525;
    background: #fff;
    .icon {
      position: absolute;
      left: 10px;
      font-size: 23px;
    }
    .header-name {
      width: 100%;
      font-size: 16px;
    }
  }
  
  .block {
    height: 50px;
  }
</style>