import axios from '@/utils/axios'

export function requestFeedback(params) {
    return axios.post('/feedback', params)
}

export function requestDetail(id) {
    return axios.get(`/feedback/detail/${id}`)
}

export function requestMyService(params) {
    return axios.get(`/feedback`, { params })
}

export function requestReply(params) {
    return axios.post('/feedback/reply', params)
}

export function requestRate(params) {
    return axios.post('/feedback/rate', params)
}
