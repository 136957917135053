import store from "@/store";
import { CHANNEL } from '@/configs/global'

function formatDate(time, format='YY-MM-DD hh:mm:ss'){
    var date = new Date(time);
    var year = date.getFullYear(),
      month = date.getMonth()+1,//月份是从0开始的
      day = date.getDate(),
      hour = date.getHours(),
      min = date.getMinutes(),
      sec = date.getSeconds();
    var preArr = Array.apply(null,Array(10)).map(function(elem, index) {
        return '0'+index;
    });
 
    var newTime = format.replace(/YY/g,year)
              .replace(/MM/g,preArr[month]||month)
              .replace(/DD/g,preArr[day]||day)
              .replace(/hh/g,preArr[hour]||hour)
              .replace(/mm/g,preArr[min]||min)
              .replace(/ss/g,preArr[sec]||sec);
 
    return newTime;
}

export const commonUtils = {
    formatDate: formatDate
}

export function getPercent(num, total) {
    /// <summary>
    /// 求百分比
    /// </summary>
    /// <param name="num">当前数</param>
    /// <param name="total">总数</param>
    num = parseFloat(num);
    total = parseFloat(total);
    if (isNaN(num) || isNaN(total)) {
        return "-";
    }
    if (num > total) {
        return 100;
    }
    return total <= 0 ? 0 : (Math.round(num / total * 10000) / 100.00);
}

export function callApp(msg, params) {
    let channel = store.getters.channel
    if (channel != CHANNEL.H5 && channel != CHANNEL.COPY_IOS && channel != CHANNEL.COPY_IOS2 && channel != CHANNEL.PC && channel != CHANNEL.MICROSOFT) {
        return window.prompt(msg, params);
    } else {
        window.top.postMessage({
            msg: msg,
            val: params
        }, "*");
    }
}

export function openWebView(url, urlParams, callBackFunc) {
    let channel = store.getters.channel

    if (urlParams) {
        let query = [];
        for(let key in urlParams){
            query.push(`${key}=${urlParams[key]}`)
        }
        url = url + "?" + query.join('&')
    }
  
    if (channel == CHANNEL.H5) {
      
        callApp("openWebView", JSON.stringify({
            "url":url, 
            "func":callBackFunc,
        }));
    } else {
        console.log(url)
        open(url);
    }
}

export function openSafariWebView(url) {
    let channel = store.getters.channel
    if (channel == CHANNEL.ZY_IOS || channel == CHANNEL.APPLE_STORE) {
        callApp("openSafariWebView", url);
    }
}

export function openUrl(url, urlParams) {
    let channel = store.getters.channel
    if (urlParams) {
        let query = [];
        for(let key in urlParams){
            query.push(`${key}=${urlParams[key]}`)
        }
        url = url + "?" + query.join('&')
    }
    if (channel != CHANNEL.H5 && channel != CHANNEL.PC) {
        callApp("openUrl", url);
    } else {
        open(url);
    }
}

export function switchAccount() {
    callApp("switchAccount");
}

// 获取链接参数
export function getQueryString(url) {
    let ItemArr = [];
    let ItemObj = {};
    url
      .substring(url.lastIndexOf("?") + 1, url.length)
      .split("&")
      .map((item) => {
        ItemArr.push(item.split("="));
      });
    ItemArr.map((item) => {
      ItemObj[item[0]] = item[1];
    });
    return ItemObj;
}