
/**
 * 反馈配置表
 */
export const feedbackMenus = [
    {
        type:0, 
        label:'LAN.feedback_0', 
        icon:"feedback/feed-icon-1.png",
        inputs: [
            {label:'LAN.feedback_server', name:"server", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_server_tips'},
            {label:'LAN.feedback_rolename', name:"rolename", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_rolename_tips'},
            {label:'LAN.feedback_price', name:"price", value:'', required:true, inputType:"number", money:true, placeholder: 'LAN.feedback_price_tips', contentJson:true},
            {label:'LAN.feedback_order', name:"order", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_order_tips', contentJson:true},
        ]
    },
    { 
        type:1, 
        label:'LAN.feedback_1',
        icon:"feedback/feed-icon-2.png",
        inputs: [
            {label:'LAN.feedback_server', name:"server", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_server_tips'},
            {label:'LAN.feedback_rolename', name:"rolename", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_rolename_tips'},
        ]
    },
    {
        type:2, 
        label:'LAN.feedback_2',
        icon:"feedback/feed-icon-4.png",
        inputs: [
            {label:'LAN.feedback_server', name:"server",  value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_server_tips'},
            {label:'LAN.feedback_rolename', name:"rolename", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_rolename_tips'},
            {label:'LAN.feedback_report', name:"report", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_report_tips', contentJson:true},
        ]
    },
    { 
        type:3, 
        label:'LAN.feedback_3',
        icon:"feedback/feed-icon-5.png",
        inputs: [
            {label:'LAN.feedback_server', name:"server",  value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_server_tips'},
            {label:'LAN.feedback_rolename', name:"rolename", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_rolename_tips'},
        ]
    },
    { 
        type:4, 
        label:'LAN.feedback_4',
        icon:"feedback/feed-icon-3.png",
        inputs: [
            {label:'LAN.feedback_username', name:"username",  value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_username_tips', contentJson:true},
            {label:'LAN.feedback_server', name:"server",  value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_server_tips'},
            {label:'LAN.feedback_rolename', name:"rolename", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_rolename_tips'},
            {label:'LAN.feedback_registerTime', name:"registerTime", value:'', required:true, inputType:"date", placeholder: 'LAN.feedback_registerTime_tips', contentJson:true},
            {label:'LAN.feedback_registerDevice', name:"registerDevice", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_registerDevice_tips', contentJson:true},
            {label:'LAN.feedback_lastLoginTime', name:"lastLoginTime", value:'', required:true, inputType:"date", placeholder: 'LAN.feedback_lastLoginTime_tips', contentJson:true},
            {label:'LAN.feedback_lastPayTime', name:"lastPayTime", value:'', required:true, inputType:"date", placeholder: 'LAN.feedback_lastPayTime_tips', contentJson:true},
            {label:'LAN.feedback_lastPayPrice', name:"lastPayPrice", value:'', required:true, inputType:"number", money:true, placeholder: 'LAN.feedback_lastPayPrice_tips', contentJson:true},
            {label:'LAN.feedback_lastPayOrder', name:"lastPayOrder", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_lastPayOrder_tips', contentJson:true},
        ]
    },
    { 
        type:5, 
        label:'LAN.feedback_5',
        icon:"feedback/feed-icon-3.png",
        inputs: [
            {label:'LAN.feedback_server', name:"server",  value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_server_tips'},
            {label:'LAN.feedback_rolename', name:"rolename", value:'', required:true, inputType:"text", placeholder: 'LAN.feedback_rolename_tips'},
        ]
    }
];